import { HANDLE_PRODUCT_FORM_DATA_CHANGE, ADD_PRODUCT } from './constant';
import { HANDLE_SELLER_FORM_DATA_CHANGE } from './constant';
const initialState = {
  steper: ['1', '2', '3'],
  productFormData: {
    category: '',
    subCategoryId: '',
    photos: [],
    itemFullFill: false,
    itemName: '',
    itemCaption: '',
    itemDescription: '',
    No_of_objects_Specific: '',
    Series: '',
    Publisher: '',
    Extras: '',
    No_of_Objects: '',
    Series_of_objects: '',
    Publisher_of_objects: '',
    price: 0,
    listAsAuction: false,
    startingAuctionPrice: '',
    reservePrice: '',
    List_of_Buy_it: false,
    buyItNowPrice: '',
    Service_info: false,
    shipWithUs: false,
    professionallyCleaned: false,
    photography: false,
    threeSixtyPhotography: false,
    shippingPaidBy: '',
    insureItem: false,
    nationwideShippingCost: '',
    nationwideShippingDeliveryDays: '',
    shippingToEuropeCost: '',
    shippingToEuropeDeliveryDays: '',
    worldwideShippingCost: '',
    worldwideShippingDeliveryDays: '',
    isDraft: false,
    SuccessListing: false,
    itemSpecifics: {},
    companyName: '',
  },
  adminSellerFormData: {
    catId: '',
    subCatId: '',
    dropdwon: false,
    textFeild: false,
    numberFeild: false,
    itemSpecificForm: [],
  },
  itemSpecificsFormData: [],
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_PRODUCT_FORM_DATA_CHANGE: {
      if (action?.payload?.name == 'itemSpecifics') {
        //first finding the length of key in itemSpecifics..
        // const itemSpecificLength = Object.keys(state.productFormData.itemSpecifics)?.length;
        let index = action.payload.index + 1;
        let keyName = `field${index}`;
        // state.productFormData.itemSpecifics = {...state.productFormData.itemSpecifics,[keyName]:action?.payload?.value}
        state.productFormData.itemSpecifics[`${keyName}`] = action?.payload?.value;
      } else if (action.payload.name === 'category') {
        state.productFormData[action?.payload?.name] = action?.payload?.value;
      } else {
        state.productFormData[action?.payload?.name] = action?.payload?.value;
      }
      return {
        ...state,
        productFormData: state.productFormData,
      };
    }
    case 'MAKE_FIELDS_EMPTY': {
      state.productFormData.companyName = '';
      state.productFormData.Extras = '';
      state.productFormData.List_of_Buy_it = false;
      state.productFormData.No_of_Objects = '';
      state.productFormData.No_of_objects_Specific = '';
      state.productFormData.Publisher = '';
      state.productFormData.Publisher_of_objects = '';
      state.productFormData.Series = '';
      state.productFormData.Series_of_objects = '';
      state.productFormData.Service_info = false;
      state.productFormData.SuccessListing = false;
      state.productFormData.buyItNowPrice = '';
      state.productFormData.category = '';
      state.productFormData.insureItem = false;
      state.productFormData.isDraft = false;
      state.productFormData.itemCaption = '';
      state.productFormData.itemDescription = '';
      state.productFormData.itemFullFill = false;
      state.productFormData.itemName = '';
      state.productFormData.itemSpecifics = {};
      state.productFormData.listAsAuction = false;
      state.productFormData.nationwideShippingCost = '';
      state.productFormData.nationwideShippingDeliveryDays = '';
      state.productFormData.photography = false;
      state.productFormData.photos = [];
      state.productFormData.price = 0;
      state.productFormData.professionallyCleaned = false;
      state.productFormData.reservePrice = '';
      state.productFormData.shipWithUs = false;
      state.productFormData.shippingPaidBy = '';
      state.productFormData.shippingToEuropeCost = '';
      state.productFormData.shippingToEuropeDeliveryDays = '';
      state.productFormData.startingAuctionPrice = '';
      state.productFormData.subCategoryId = '';
      state.productFormData.threeSixtyPhotography = false;
      state.productFormData.worldwideShippingCost = '';
      state.productFormData.worldwideShippingDeliveryDays = '';
      return {
        ...state,
        productFormData: state.productFormData,
      };
    }
    case HANDLE_SELLER_FORM_DATA_CHANGE: {
      state.adminSellerFormData.subCatId = action.payload;
      return {
        ...state,
        adminSellerFormData: state.adminSellerFormData,
      };
    }
    case ADD_PRODUCT: {
      // state.productFormData[action?.payload?.name] = action?.payload?.value;
      return {
        ...state,
        SuccessListing: true,
        // productFormData: state.productFormData,
      };

      break;
    }
    case 'ADD_ITEM_SPECIFICS':
      state.itemSpecificsFormData = action?.payload?.value || [];
      return {
        ...state,
        itemSpecificsFormData: state.itemSpecificsFormData,
      };

    default:
      return state;
  }
};
export default productReducer;
