import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import UserAccount from '../../assests/account.svg';
import useWindowSize from '../../hooks/useWindowSize'; // replace with the actual path
import NotificationComponent from './NotificationComponent';
import { CategoriesButton } from './categoriesButton';
import './style.css';

const { SubMenu } = Menu;

export const NavBurger = () => {
  let history = useHistory();
  const name = localStorage.getItem('emailuSer');
  const displayName = localStorage.getItem('displayName');
  const size = useWindowSize();

  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('uid');
    localStorage.removeItem('emailuSer');
    localStorage.removeItem('displayName');
    localStorage.removeItem('sellerAccount');
    localStorage.removeItem('paymentDetailsAdded');
    history.push('/');
    window.location.reload();
  };

  let isLogin = localStorage.getItem('uid');

  return (
    <>
      <Button type="text" onClick={showDrawer}>
        <MenuOutlined style={{ color: 'white', fontSize: '18px' }} />
      </Button>
      <Drawer
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ color: 'white' }}>Menu</div>
            <Button onClick={onClose} type="text" style={{ color: 'white' }}>
              <CloseOutlined />
            </Button>
          </div>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        drawerStyle={{ backgroundColor: '#212529' }}
        width={size.width <= 350 ? '100%' : 350} // Set the width based on the window's width
      >
        <Menu mode="vertical" style={{ width: '100%', lineHeight: '64px', border: 0 }}>
          <Col
            style={{
              backgroundColor: '#212529',
            }}
            xs={{ span: 100 }}
            className="menu_right"
          >
            <Menu
              mode="vertical"
              style={{
                backgroundColor: '#212529',
                width: '100%',
                lineHeight: '64px',
                color: '#fff',
                border: 0,
              }}
            >
              {isLogin && (
                <Menu.Item key="watchlist" style={{ fontSize: '28px' }}>
                  <Link to="/my-account/Watchlist"> {t('watchlist')}</Link>
                </Menu.Item>
              )}
              {isLogin && (
                <Menu.Item className="tittle-drop" key="sell">
                  <Link to="/sellar-dashboard/List an item">{t('sell')}</Link>
                </Menu.Item>
              )}
              <Menu.Item className="tittle-drop">
                <Link to="/support-center" style={{ color: 'white' }}>
                  {t('help')}
                </Link>
              </Menu.Item>
              <Menu.Item
                className="tittle-drop"
                style={{
                  paddingLeft: '45px',
                }}
              >
                <CategoriesButton showButton={false} />
              </Menu.Item>
              {isLogin && (
                <Menu.Item key="notification">
                  <NotificationComponent />
                </Menu.Item>
              )}
              {name || displayName ? (
                <>
                  <Menu.Item key="9" className="tittle-drop">
                    <Link to="/my-account/My bids">{t('myBids')}</Link>
                  </Menu.Item>
                  <Menu.Item key="10" className="tittle-drop">
                    <Link to="/my-account">{t('myAccount')}</Link>
                  </Menu.Item>

                  <Menu.Item key="Sellar Account" className="tittle-drop">
                    <Link to="/sellar-dashboard">{t('sellerAccount')}</Link>
                  </Menu.Item>
                  <Menu.Item key="Sign Out" className="tittle-drop" onClick={handleLogout}>
                    {t('signOut')}
                  </Menu.Item>
                </>
              ) : (
                <Menu.Item
                  key="sign"
                  className="user-account-head text-end"
                  style={{ width: 'max-content' }}
                >
                  <Link to="/seller-login">
                    {t('signIn')} &nbsp;{' '}
                    <img width={23} height={23} src={UserAccount} className="user-account" />
                  </Link>
                </Menu.Item>
              )}
            </Menu>
          </Col>
        </Menu>
      </Drawer>
    </>
  );
};
