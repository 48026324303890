const initialState = {
  searchResults: { loading: false, data: [], error: null },
};

const GetSearchResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SEARCH_RESULT_SUCCESS':
      return {
        ...state,
        searchResults: action.payload,
      };
    default:
      return state;
  }
};

export default GetSearchResultsReducer;
