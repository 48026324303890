import React, { useState } from 'react';
import PageLayout from '../../layout/PageLayout';
import HeaderInner from './HeaderInner';
import HeaderOuter from './HeaderOuter';
import { useAuthContext } from './../../Context/authContext';
import HeaderAdminInner from './HeaderAdminInner';
const HeadersSection = () => {
  const { getRole } = useAuthContext();

  return (
    <PageLayout title="Header">
      {(getRole() === 'admin' && window.location.pathname.split('/')[1] === 'admin-details') ||
      window.location.pathname.split('/')[1] === 'admin-login' ? (
        <HeaderAdminInner />
      ) : (
        <>
          <HeaderInner />
          <HeaderOuter />
        </>
      )}
    </PageLayout>
  );
};
export default HeadersSection;
