import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { useAuthContext } from '../../Context/authContext';
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuthContext();
  return (
    <Route
      {...rest}
      render={(props) => {
        // const token = localStorage.getItem('token');
        // const sellerAccount = localStorage.getItem('sellerAccount');
        // const uid = localStorage.getItem('uid');
        // const emailuSer = localStorage.getItem('emailuSer');

        if (isAuthenticated()) {
          return <Component {...props} />;
        } else {
          props.history.push('/');
        }
      }}
    />
  );
};
export default withRouter(ProtectedRoute);
