import { Col, Layout } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FacebookIcon from '../assests/fb.png';
import InstaIcon from '../assests/insta.png';
import TwitterIcon from '../assests/twitter.png';
import HeadersSection from '../components/Header/index';
import useWindowSize from '../hooks/useWindowSize';
import './MainLayout.less';

const { Footer } = Layout;

const MainLayout = ({ children, t }) => {
  const size = useWindowSize();

  return (
    <Layout>
      <HeadersSection />
      <Layout>
        {children}
        <Footer className="footer">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: size.width > 768 ? 'row' : 'column',
              alignItems: 'flex-start',
              gap: '20px',
              width: '100%',
              marginLeft: size.width > 768 ? 0 : '10%',
            }}
          >
            <Col
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 5, offset: 0 }}
              md={{ span: 5, offset: 3 }}
              lg={{ span: 5, offset: 3 }}
              xl={{ span: 5, offset: 3 }}
              xxl={{ span: 5, offset: 3 }}
              className="footer-col"
            >
              <p style={{ color: '#FCA311', fontSize: 22, textAlign: 'justify' }}>{t('amibid')}</p>
              <p style={{ color: 'white', textAlign: 'justify' }}>
                <Link to="/about" style={{ color: 'white' }}>
                  {t('footer.about')}
                </Link>
              </p>
              <p style={{ color: 'white', textAlign: 'justify' }}>{t('footer.blog')}</p>
              <p style={{ color: 'white', textAlign: 'justify' }}>{t('footer.press')}</p>
              <p style={{ color: 'white', textAlign: 'justify' }}>
                <Link to="/terms-conditions" style={{ color: 'white' }}>
                  {t('footer.terms')}
                </Link>
              </p>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 5 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
              xl={{ span: 5 }}
              xxl={{ span: 5 }}
              className="footer-col"
            >
              <p style={{ color: '#FCA311', fontSize: 22, textAlign: 'justify' }}>
                {t('footer.selling')}
              </p>
              <p style={{ color: 'white', textAlign: 'justify' }}>{t('footer.Seller’s guide')}</p>
              <p style={{ color: 'white', textAlign: 'justify' }}>
                <Link to="/List-items" style={{ color: 'white' }}>
                  {t('listAnItem')}
                </Link>
              </p>
              <p style={{ color: 'white', textAlign: 'justify' }}>
                <Link to="/contact-us" style={{ color: 'white' }}>
                  {t('footer.conactus')}
                </Link>
              </p>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 5 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
              xl={{ span: 5 }}
              xxl={{ span: 5 }}
              className="footer-col"
            >
              <p style={{ color: '#FCA311', fontSize: 22, textAlign: 'justify' }}>
                {t('footer.Buying')}
              </p>
              <p style={{ color: 'white', textAlign: 'justify' }}>
                <Link to="/catagories" style={{ color: 'white' }}>
                  {t('footer.Categories')}
                </Link>
              </p>
              <p style={{ color: 'white', textAlign: 'justify' }}>
                <Link to="/my-account/Watchlist" style={{ color: 'white' }}>
                  {t('footer.Watchlist')}
                </Link>
              </p>
              <p style={{ color: 'white', textAlign: 'justify' }}>{t('footer.Buyer’s guide')}</p>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 5 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
              xl={{ span: 5 }}
              xxl={{ span: 5 }}
              className="footer-col"
            >
              <p style={{ color: '#FCA311', fontSize: 22, textAlign: 'justify' }}>
                {t('footer.Account')}
              </p>
              <p style={{ color: 'white', textAlign: 'justify' }}>
                <Link to="/" style={{ color: 'white' }}>
                  {t('footer.Log in')}
                </Link>
              </p>
              <p style={{ color: 'white', textAlign: 'justify' }}>
                <Link to="/user-account" style={{ color: 'white' }}>
                  {t('footer.Register')}
                </Link>
              </p>
              <p style={{ color: 'white', textAlign: 'justify' }}>
                <Link to="/sellar-dashboard" style={{ color: 'white' }}>
                  {t('footer.Seller Dashboard')}
                </Link>
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  width: 108,
                  marginTop: "20px",
                }}
              >
                <img width={12} height={23} src={FacebookIcon} alt="facebook" />
                <img width={21} height={23} src={InstaIcon} alt="Instagram" />
                <img width={24} height={23} src={TwitterIcon} alt="Twitter" />
              </div>
            </Col>
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
export default withTranslation()(MainLayout);
