import { hot } from 'react-hot-loader';
import React from 'react';
import AppRouter from './routes';
import './App.css';
import './styles/bootstrap-utilities.css';
import firebase from './firebase';
import 'bootstrap/dist/css/bootstrap.min.css';

// const App = () => {
//   React.useEffect(() => {
//     const msg = firebase.messaging();
//     msg
//       .requestPermission()
//       .then(() => {
//         return msg.getToken();
//       })
//       .then((data) => {
//         console.warn('token', data);
//       })
//       .catch((err) => {
//         console.log('::: err', err);
//       });
//   });
//   return <AppRouter />;
// };

const App = () => {
  React.useEffect(() => {
    const msg = firebase.messaging();
    Notification.requestPermission()
      .then(() => {
        return msg.getToken();
      })
      .then((data) => {
        console.warn('token', data);
      })
      .catch((err) => {
        console.log('::: err', err);
      });
  });
  return <AppRouter />;
};

export default hot(module)(App);
