import { Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import NotificationIcon from './../../assests/NotificationIcon';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const NotificationComponent = () => {
  const { t } = useTranslation();
  const { notifications } = useSelector((state) => state.GetNotificationReducer);
  const [unreadNotifications, setUndreadNotifications] = useState([]);

  const GetUnreadNotfications = useMemo(
    () => notifications?.data?.filter((notification) => notification.read === false),
    [notifications],
  );

  let UnreadNotifications = notifications && GetUnreadNotfications;

  useEffect(() => {
    setUndreadNotifications(UnreadNotifications);
  }, [notifications]);

  const history = useHistory();

  const handleNotification = (item) => () => {
    // redirect to a URL
    let URL = '';

    switch (item?.type) {
      case 'AUCTION':
        URL = `/items/:${item?.item?.id}/auction`;
        break;
      case 'SOLD':
        URL = `/items/:${item?.item?.id}/${item?.item?.listAsAuction ? 'auction' : 'buyer'}`;
        break;
      case 'DISPUTE':
        URL = `/my-account/disputes-details/:${item?.itemDisputeId}`;
        break;
      case 'PAYMENT':
        URL = `pay-items/:${item?.item?.id}}`;
      default:
        return;
    }

    if (URL && URL !== '') {
      history.push(URL);
    }

    // mark unread

    axios
      .post(
        'https://us-central1-amibid-24a48.cloudfunctions.net/default-markNotificationRead-default',
        {
          data: {
            uid: localStorage.getItem('uid'),
            notificationId: item?.id,
          },
        },
      )
      .then((res) => {
        console.log('res', res);
        if (res?.data?.result?.status == 200) {
          axios
            .post(
              'https://us-central1-amibid-24a48.cloudfunctions.net/default-getNotifications-default',
              {
                data: {
                  uid: localStorage.getItem('uid'),
                  // uid: '001',
                  limit: 10,
                  offset: 0,
                },
              },
            )
            .then((res) => {
              if (res?.data?.result?.status == 200) {
                setUndreadNotifications(
                  res?.data?.result?.data?.filter((notif) => notif.read === false),
                );
              }
            });
        }
      })
      .catch(() => {
        // console.log('error');
      });
  };

  const menu = (
    <Menu className="notification-container">
      <Menu.Item key="1">
        <ul>
          {unreadNotifications && unreadNotifications.length > 0 ? (
            unreadNotifications?.map((item) => (
              <li key={item} onClick={handleNotification(item)}>
                <div className="notification-item">
                  {item?.item?.photos && item?.item?.photos?.length > 0 && (
                    <div>
                      <span
                        className="notification-img"
                        style={{ backgroundImage: `url("${item.item.photos[0]}")` }}
                      />
                    </div>
                  )}
                  <div className="ms-4 flex-1">
                    <h3 className="mb-0">{item?.title}</h3>
                    <p className="mb-0">{item?.body}</p>
                  </div>
                  <div>
                    <span className="indicator" />
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className="empty-state">{t('noNotifications')}</div>
          )}
        </ul>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="notifications-wrapper">
      <Dropdown overlay={menu} trigger={['click']} placement="topLeft">
        <a onClick={(e) => e.preventDefault()} style={{ position: 'relative' }}>
          <NotificationIcon />
          {unreadNotifications?.length > 0 && <span className="unreadNotificationDot"></span>}
        </a>
      </Dropdown>
    </div>
  );
};

export default NotificationComponent;
