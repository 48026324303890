import { DownOutlined } from '@ant-design/icons';
import { AutoComplete, Col, Form, Input, Menu, Row, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { SEARCH_CATEGORY } from '../../API/Endpoints';
import UserAccount from '../../assests/account.svg';
import Loader from '../../components/Loader/index';
import useWindowSize from '../../hooks/useWindowSize'; // replace with the actual path
import { notificationAction } from './../../store/Notifications/action';
import NotificationComponent from './NotificationComponent';
import { CategoriesButton } from './categoriesButton';
import { NavBurger } from './navBurger';
import './style.css';

const { Option } = Select;
const { SubMenu } = Menu;

export default function HeaderInner() {
  let history = useHistory();
  const name = localStorage.getItem('emailuSer');
  const displayName = localStorage.getItem('displayName');
  const { t } = useTranslation();
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState([]);
  const [category, setCatgory] = useState([]);
  const [focus, setFocus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const size = useWindowSize();

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const onSearch = () => {
    if (searchValue === '' && selectedCategoryId === 'all') {
      history.push({
        pathname: '/catagoriesDetails/:all',
      });
      return;
    }

    dispatch({
      type: 'GET_SEARCH_RESULT_SUCCESS',
      payload: { loading: true },
    });
    setLoading(true);

    axios
      .post(SEARCH_CATEGORY, {
        data: {
          categoryId: selectedCategoryId === '' ? null : selectedCategoryId,
          term: searchValue,
          limit: 10,
          offset: 0,
        },
      })
      .then(
        (response) => {
          const { status, message, data } = response.data.result;
          if (status == 200) {
            dispatch({
              type: 'GET_SEARCH_RESULT_SUCCESS',
              payload: { loading: false, error: null, data: data },
            });
            history.push('/catagoriesDetails/search');
          } else {
            dispatch({
              type: 'GET_SEARCH_RESULT_SUCCESS',
              payload: { loading: false },
            });
          }
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          dispatch({
            type: 'GET_SEARCH_RESULT_SUCCESS',
            payload: { loading: false },
          });
        },
      );
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('uid');
    localStorage.removeItem('emailuSer');
    localStorage.removeItem('displayName');
    localStorage.removeItem('sellerAccount');
    localStorage.removeItem('paymentDetailsAdded');
    history.push('/');
    window.location.reload();
  };

  useEffect(() => {
    axios
      .post('https://us-central1-amibid-24a48.cloudfunctions.net/default-getCategories-default', {
        data: {
          sortByNameAsc: true,
        },
      })
      .then(
        (response) => {
          const { status, message, data } = response.data.result;
          if (status == 200) {
            // setLoading(false);
            setCatgory(data.categories);
          }
        },
        (error) => {
          console.log('error', error);
        },
      );
  }, []);

  // dispatch get notifciations action

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(notificationAction.getNotifications());
  }, []);

  let isLogin = localStorage.getItem('uid');

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div className="header">
        <section className="container-top blue-grid">
          <Row gutter={15} className="header-row justify-content-between">
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 11 }}
              lg={{ span: 2 }}
              xl={{ span: 2 }}
              xxl={{ span: 2 }}
            >
              <div className="logo-section">
                <Link to="/">
                  <h1 className="logo">{t('amibid')}</h1>
                </Link>
              </div>
            </Col>
            {size.width > 768 ? <CategoriesButton /> : <NavBurger />}

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 10 }}
              lg={{ span: 7 }}
              xl={{ span: 9 }}
              xxl={{ span: 10 }}
              className="search-option"
            >
              <Form
                name="complex-form"
                // className={focus ? 'header_form active' : 'header_form'}
                className={'header_form'}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              >
                <Form.Item className="mb-0">
                  <div className="all_search" style={{ width: '10%' }}>
                    <Select
                      onSelect={(e) => setSelectedCategoryId(e)}
                      showSearch
                      style={{ width: '100% ', borderRadius: '5px 0 0px 5px' }}
                      className="dropdown-select"
                      placeholder={t('all')}
                      optionFilterProp="children"
                      dropdownMatchSelectWidth={false}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      defaultValue={'all'}
                    >
                      <Option value="all" key={0} onSelect={(e) => console.log(e)}>
                        {t('all')}
                      </Option>
                      {category &&
                        category.map((item, i) => (
                          <Option value={item.id} key={i}>
                            {t(`${item.name.trim().split(' ').join('_')}`)}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <div style={{ width: '100%' }}>
                    <AutoComplete
                      className="header-auto-complete"
                      dropdownMatchSelectWidth={252}
                      options={options}
                      onSearch={handleSearch}
                    >
                      <Input.Search
                        loading={loading}
                        size="large"
                        placeholder=""
                        onSearch={onSearch}
                        style={{ marginLeft: '-2px' }}
                      />
                    </AutoComplete>
                  </div>
                </Form.Item>
              </Form>
            </Col>
            {size.width > 768 && (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 14 }}
                lg={{ span: 11 }}
                xl={{ span: 9 }}
                xxl={{ span: 8 }}
                className="menu_right"
              >
                <Menu mode="horizontal">
                  {isLogin && (
                    <Menu.Item key="watchlist">
                      <Link to="/my-account/Watchlist"> {t('watchlist')}</Link>
                    </Menu.Item>
                  )}

                  {isLogin && (
                    <Menu.Item className="tittle-drop" key="sell">
                      <Link to="/sellar-dashboard/List an item">{t('sell')}</Link>
                    </Menu.Item>
                  )}
                  {isLogin && (
                    <Menu.Item key="notification">
                      <NotificationComponent />
                    </Menu.Item>
                  )}
                  {name || displayName ? (
                    <SubMenu
                      style={{ width: 'max-content', minWidth: '150px !important' }}
                      key="sub2"
                      className="tittle-drop mar_left"
                      title={
                        name
                          ? `${t('hi')} ${name.split('@')[0].substring(0, 7) + '...'}`
                          : displayName
                          ? `${t('hi')} ${displayName}`
                          : ''
                      }
                      icon={<DownOutlined className="arrow_down" />}
                    >
                      <Menu.Item key="9" className="profile_drop">
                        <Link to="/my-account/My bids">{t('myBids')}</Link>
                      </Menu.Item>
                      <Menu.Item key="10" className="profile_drop">
                        <Link to="/my-account">{t('myAccount')}</Link>
                      </Menu.Item>

                      <Menu.Item key="Sellar Account" className="profile_drop">
                        <Link to="/sellar-dashboard">{t('sellerAccount')}</Link>
                      </Menu.Item>
                      <Menu.Item key="Sign Out" className="profile_drop" onClick={handleLogout}>
                        {t('signOut')}
                      </Menu.Item>
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key="sign"
                      className="user-account-head text-end"
                      style={{ width: 'max-content' }}
                    >
                      <Link to="/seller-login">
                        {t('signIn')} &nbsp;{' '}
                        <img width={23} height={23} src={UserAccount} className="user-account" />
                      </Link>
                    </Menu.Item>
                  )}
                </Menu>
              </Col>
            )}
          </Row>
        </section>
      </div>
    );
  }
}
