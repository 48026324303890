export const MyAccountConst = {
  ADD_SHIPPING_ADDRESS_SUCCESS: 'ADD_SHIPPING_ADDRESS_SUCCESS',
  ADD_SHIPPING_ADDRESS_ERROR: 'ADD_SHIPPING_ADDRESS_ERROR',
  CLOSE_NOTIFI_SUCCESS: 'CLOSE_NOTIFI_SUCCESS',
  ADD_CARD_SUCCESS: 'ADD_CARD_SUCCESS',
  GET_SHIPPING_SUCCESS: 'GET_SHIPPING_SUCCESS',
  GET_CARD_SUCCESS: 'GET_CARD_SUCCESS',
  SHIPPING_FOR_PAYMENT: 'SHIPPING_FOR_PAYMENT',
  GET_MYORDER_SUCCESS: 'GET_MYORDER_SUCCESS',
  GET_PURCHASE_HISTORY: 'GET_PURCHASE_HISTORY',
  GET_DISPUTES_SUCCESS: 'GET_DISPUTES_SUCCESS',
  GET_NEW_DISPUTES_SUCCESS: 'GET_NEW_DISPUTES_SUCCESS',
  GET_DISPUTES_COMMENTS_SUCCESS: 'GET_DISPUTES_COMMENTS_SUCCESS',
  GET_WATCHLIST_ITEM_SUCCESS: 'GET_WATCHLIST_ITEM_SUCCESS',
  CHECKED_BOX_SUCCESS: 'CHECKED_BOX_SUCCESS',
  CHECKED_BOX_CARD_SUCCESS: 'CHECKED_BOX_CARD_SUCCESS',
  ALL_CHECKED_FALSE: 'ALL_CHECKED_FALSE',
  ALL_CHECKED_CARD_FALSE: 'ALL_CHECKED_CARD_FALSE',
};
