export const SellerConst = {
  GET_SELLER_ITEM_REQUEST: 'GET_SELLER_ITEM_REQUEST',
  GET_SELLER_ITEM_SUCCESS: 'GET_SELLER_ITEM_SUCCESS',
  GET_SELLER_ITEM_ERROR: 'GET_SELLER_ITEM_ERROR',
  SHIPPING_TO_FULLMINT_SUCCESS: 'SHIPPING_TO_FULLMINT_SUCCESS',
  SHIPPING_FROM_FULLMINT_SUCCESS: 'SHIPPING_FROM_FULLMINT_SUCCESS',
  UPDATE_TRACKING_INFO: 'UPDATE_TRACKING_INFO',
  GET_ACTIVE_ITEMS_SUCCESS: 'GET_ACTIVE_ITEMS_SUCCESS',
  ADD_LOWER_PRICE_SUCCESS: 'ADD_LOWER_PRICE_SUCCESS',
  REMOVE_NOTIFICATIONS: 'REMOVE_NOTIFICATIONS',
  GET_SELLER_DISPUTES_SUCCESS: 'GET_SELLER_DISPUTES_SUCCESS',
  GET_SOLD_ITEMS_SUCCESS: 'GET_SOLD_ITEMS_SUCCESS',
  GET_UNSOLD_ITEMS_SUCCESS: 'GET_UNSOLD_ITEMS_SUCCESS',
  GET_UNSOLD_ITEMS_ERROR: 'GET_UNSOLD_ITEMS_ERROR',
  GET_SELLER_REVIEWS_SUCCESS: 'GET_SELLER_REVIEWS_SUCCESS',
};
