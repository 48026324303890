import { UnorderedListOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const CategoriesButton = ({ showButton = true }) => {
  const { t } = useTranslation();

  return (
    <Col
      xs={{ span: 12 }}
      sm={{ span: 12 }}
      md={{ span: 11 }}
      lg={{ span: 3 }}
      xl={{ span: 3 }}
      xxl={{ span: 2 }}
    >
      <Link to="/catagories">
        <div className="catagories">
          {showButton && <UnorderedListOutlined className="icons" />}
          <h1 className="catagories-text">{t('categories')}</h1>
        </div>
      </Link>
    </Col>
  );
};
