import axios from 'axios';

const getNotifications = () => (dispatch) => {
  dispatch({ type: 'GET_NOTIFICATION_SUCCESS', payload: { loading: true } });
  axios
    .post('https://us-central1-amibid-24a48.cloudfunctions.net/default-getNotifications-default', {
      data: {
        uid: localStorage.getItem('uid'),
        // uid: '001',
        limit: 10,
        offset: 0,
      },
    })
    .then(
      (response) => {
        const { status, message, data } = response.data.result;
        if (status == 200) {
          console.log('data => ', data);
          dispatch({
            type: 'GET_NOTIFICATION_SUCCESS',
            payload: { loading: false, error: null, data: data },
          });
        } else {
          dispatch({
            type: 'GET_NOTIFICATION_SUCCESS',
            payload: { loading: false },
          });
        }
      },
      (error) => {
        setLoader(false);
        dispatch({
          type: 'GET_NOTIFICATION_SUCCESS',
          payload: { loading: false, error: error.data },
        });
      },
    );
};

export const notificationAction = {
  getNotifications,
};
