const initialState = {
  notifications: { loading: false, data: [], error: null },
};

const GetNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_NOTIFICATION_SUCCESS':
      console.log('action.payload => ', action.payload);
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
};

export default GetNotificationReducer;
