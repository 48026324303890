import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './style.css';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Loader/index';
import { GET_CATEGORIES } from '../../API/Endpoints';
import { useAuthContext } from '../../Context/authContext';

export default function HeaderAdminInner() {
  const { getRole, logout } = useAuthContext();

  let location = useLocation();
  let history = useHistory();
  const name = localStorage.getItem('emailuSer');
  const displayName = localStorage.getItem('displayName');

  const { t, i18n } = useTranslation();
  const [category, setCatgory] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleLogout = async () => {
    // localStorage.removeItem('token');
    // localStorage.removeItem('uid');
    // localStorage.removeItem('emailuSer');
    // localStorage.removeItem('displayName');
    await logout();
    history.push('/admin-login');
    window.location.reload();
  };

  useEffect(() => {
    setLoader(false);
    axios
      .post(GET_CATEGORIES, {
        data: {
          sortByNameAsc: true,
        },
      })
      .then(
        (response) => {
          const { status, message, data } = response.data.result;
          if (status == 200) {
            // setLoading(false);
            setCatgory(data.categories);
            setLoader(false);
          }
        },
        (error) => {
          setLoader(false);
        },
      );
  }, []);

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div className="header">
        <section className="container-top blue-grid p-4 px-5">
          <div className="d-flex justify-content-between">
            <div>
              <div className="logo-section">
                <Link to="/admin-details">
                  <h1 className="logo">
                    {t('amibid')} <span style={{ color: '#FEA400' }}>{t('Admin')}</span>
                  </h1>
                </Link>
              </div>
            </div>
            <div>
              {window.location.pathname.split('/')[1] !== 'admin-login' && (
                <div
                  onClick={handleLogout}
                  style={{ color: '#ffffff', cursor: 'pointer', fontWeight: 600 }}
                >
                  {t('signOut')}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
