import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import ProvideAuth from './Context/authContext';
import ProvideCurrency from './Context/currencyContext';
import './i18n';
<script src="https://apis.google.com/js/api.js"></script>;
ReactDOM.render(
  <ProvideCurrency>
    <ProvideAuth>
      <Provider store={store}>
        <App />
      </Provider>
    </ProvideAuth>
  </ProvideCurrency>,
  document.querySelector('#root'),
);
