import { combineReducers } from 'redux';
import userSignUp from './signUp/reducer';
import productReducer from './product/reducer';
import SellerReducer from './SellerDashboard/reducer';
import userPaymentReducer from './payment/reducer';
import myAccountReducer from './myAccount/reducer';
import GetCategoriesReducer from './Categories/reducer';
import GetNotificationReducer from './Notifications/reducer';
import GetSearchResultsReducer from './SearchResults/reducer';

const rootReducer = combineReducers({
  userSignUp,
  productReducer,
  SellerReducer,
  userPaymentReducer,
  myAccountReducer,
  GetCategoriesReducer,
  GetNotificationReducer,
  GetSearchResultsReducer,
});

export default rootReducer;
