import { Menu, Select } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrency } from '../../Context/currencyContext';
import franceFlag from '../../assests/french-flag.png';
import ukFlag from '../../assests/united-kingdom.png';
const { Option } = Select;
const { SubMenu } = Menu;

export default function HeaderOuter() {
  const [currencies, setCurrencies] = useState([]);
  const { currency, setCurrency } = useCurrency();
  const [flag, setFlag] = React.useState(ukFlag);
  const { t, i18n } = useTranslation('translation');
  const [loading, setLoading] = React.useState(false);
  const [language, setLanguage] = useState('English');
  function handleChange(value) {
    i18n.changeLanguage(`${value}`);
    if (value === 'en') {
      setFlag(ukFlag);
      setLanguage('English');
    } else {
      setFlag(franceFlag);
      setLanguage('Français');
    }
  }
  const handleChangeCurency = (value) => {
    updateCurrency(value);
  };

  React.useEffect(() => {}, [currency]);

  const updateCurrency = (curr) => {
    setLoading(true);
    axios
      .post(
        'https://us-central1-amibid-24a48.cloudfunctions.net/currency-updateUserCurrency-default',
        {
          data: {
            uid: localStorage.getItem('uid'),
            currency: curr,
          },
        },
      )
      .then(
        (response) => {
          setLoading(false);
          console.log(response);
          if (response.data.result.status === 200) {
            setCurrency(curr);
            localStorage.setItem('currency', curr);
          }
        },
        (error) => {
          setLoading(false);
          console.log('error', error);
        },
      );
  };

  React.useEffect(() => {
    axios
      .post('https://us-central1-amibid-24a48.cloudfunctions.net/default-metadata-default', {
        data: {},
      })
      .then(
        (response) => {
          setCurrencies(response.data.result.data.currencies);
        },
        (error) => {
          console.log('error', error);
        },
      );
  }, []);

  return (
    <div>
      <section className="container2 blue-grid">
        <div
          style={{
            width: '100%',
            overflowX: 'auto', // Enable horizontal scrolling
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '34px',
              fontSize: '14px',
              minWidth: '300px', // Minimum width to prevent collapsing
              flexWrap: 'nowrap',
              padding: '0 20px',
              whiteSpace: 'nowrap', // Prevent line breaks within the container
            }}
          >
            <div>
              {
                <Select
                  onChange={handleChangeCurency}
                  defaultValue={currency}
                  className="language-select"
                >
                  {currencies.map((currency) => (
                    <Option value={currency}>{currency}</Option>
                  ))}
                </Select>
              }
            </div>
            <div>
              <img width={25} height={25} src={flag} />
              <Select value={language} className="language-select" onChange={handleChange}>
                <Option value="en">English</Option>
                <Option value="fr">{t('french')}</Option>
              </Select>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
