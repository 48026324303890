import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainLayout from './layout/MainLayout';
import RouteWithSubRoutes from './RouteWithSubRoutes';
import { Route, withRouter, Redirect } from 'react-router-dom';
const Home = lazy(() => import('pages/Signin/index'));
const UserSignUp = lazy(() => import('pages/Account/userSignUp'));
const About = lazy(() => import('pages/about/About'));
const Contact = lazy(() => import('pages/contact/Contact'));
const ForgetPassword = lazy(() => import('pages/Signin/ForgetPassword'));
const UserAccount = lazy(() => import('pages/Account/index.js'));
const SellarAccount = lazy(() => import('pages/SellerAccount/index.js'));
const HomePage = lazy(() => import('pages/homepage/index.js'));
const CurrentItemDetails = lazy(() => import('pages/ItemsDetails/index.js'));
const Catagories = lazy(() => import('pages/catagories/index.js'));
const CatagoriesDetails = lazy(() => import('pages/catagories/CatagoriesDetails.js'));
const SellarDashboard = lazy(() => import('pages/SellarDashBoard/index.js'));
const MyAccount = lazy(() => import('pages/myAccount/index.js'));
const SunmitDisputes = lazy(() =>
  import('pages/myAccount/Disputes/SunmitDisputes/SunmitDisputes.js'),
);
const PayItems = lazy(() => import('pages/myAccount/PayItems/index.js'));
const paymentMethod = lazy(() => import('pages/itemSell/paymentMethod.js'));
const sellingItem = lazy(() => import('pages/itemSell/sellingItem.js'));
const ListItems = lazy(() => import('pages/Listitems/index.js'));
const ThankSubmit = lazy(() => import('pages/Listitems/ThanksPage/ThankSubmit.js'));
const ThnksReviewPage = lazy(() =>
  import('pages/myAccount/PurchaseHistory/ThnksReviewPage/ThankSubmit.js'),
);
const SupporCenter = lazy(() => import('pages/SupportCenter/index.js'));
const BuyItems = lazy(() => import('pages/SupportCenter/BuyItems.js'));
const TermsConditions = lazy(() => import('pages/TermsCondition/index.js'));

const ContactForm = lazy(() => import('pages/SupportCenter/ContactForm.js'));
const PaymentSuccess = lazy(() => import('pages/myAccount/PayItems/PaymentSuccess.js'));
const SellerLogin = lazy(() => import('pages/Account/SellerLogin.js'));
const AdminDetalis = lazy(() => import('pages/AdminPanel/AdminDetails/index.js'));
const AdminLogin = lazy(() => import('pages/AdminPanel/index.js'));
const SellerReviews = lazy(() => import('./pages/ItemsDetails/SellerReviews'));
import { BlockLoading } from 'react-loadingg';
import { useAuthContext } from './Context/authContext';

const routes = [
  {
    exact: true,
    path: '/thanks-submit-items',
    component: ThankSubmit,
  },

  // {
  //   path: '/forget-password',
  //   component: ForgetPassword,
  // },
  {
    path: '/user-details',
    exact: true,
    component: UserSignUp,
  },
  {
    path: '/user-account',
    component: UserAccount,
  },

  {
    path: '/sellar-dashboard',
    component: SellarDashboard,
  },
  {
    path: '/my-account',
    component: MyAccount,
  },
  {
    path: '/sell-items',
    component: paymentMethod,
  },
  {
    path: '/selling-item',
    component: sellingItem,
  },

  {
    path: '/List-items',
    component: ListItems,
  },
  {
    path: '/pay-items/:id',
    component: PayItems,
  },
  // {
  //   path: '/buy-items',
  //   component: BuyItems,
  // },

  {
    path: '/payment-success',
    component: PaymentSuccess,
  },

  {
    exact: true,
    path: '/thnks-reviews',
    component: ThnksReviewPage,
  },
  {
    exact: true,
    path: '/Submited-disputes',
    component: SunmitDisputes,
  },

  // {
  //   exact: true,
  //   path: '/admin-details',
  //   component: AdminDetalis,
  // },
];

const AppRouter = () => {
  return (
    <Router>
      <MainLayout>
        <Suspense
          fallback={
            <div className="lazy-loading">
              <BlockLoading speed="22" size="large" color="#14213D">
                fg
              </BlockLoading>
            </div>
          }
        >
          <ProtectedRoute path="/admin-details" component={AdminDetalis} />
          {/* <Route path="/user-details" exact component={UserSignUp} /> */}
          <Route path="/seller-login" exact component={SellerLogin} />

          <Route path="/" exact component={HomePage} />
          {/* <Route path="/" exact component={SellerLogin} /> */}
          <Route path="/seller-account" exact component={SellarAccount} />
          <Route path="/homepage" component={HomePage} />
          <Route path="/admin-login" component={AdminLogin} />
          <Route path="/forget-password" component={ForgetPassword} />
          <Route path="/catagories" component={Catagories} />
          <Route path="/catagoriesDetails/:id" component={CatagoriesDetails} />
          <Route path="/support-center" component={SupporCenter} />
          <Route path="/contact-us" component={ContactForm} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={About} />
          <Route path="/terms-conditions" component={TermsConditions} />
          <Route path="/items/:id/buyer" component={CurrentItemDetails} />
          <Route path="/items/:id/auction" component={CurrentItemDetails} />
          <Route path="/items/:id/recommended" component={CurrentItemDetails} />
          <Route path="/seller-reviews/:id/:name" component={SellerReviews} />

          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Suspense>
      </MainLayout>
    </Router>
  );
};

export default AppRouter;

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuthContext();

  return (
    <>
      {isAuthenticated() ? (
        <>
          <main>
            <Route {...rest} render={(props) => <Component {...props} />} />
          </main>
        </>
      ) : window.location.pathname.split('/')[1] === 'admin-details' ||
        window.location.pathname.split('/')[1] === 'admin-login' ? (
        <Redirect to="/admin-login" />
      ) : (
        <>{/* <Redirect to="/" /> */}</>
      )}
    </>
  );
};
