import React from 'react';
import { useState, createContext, useContext } from 'react';
const currencyContext = createContext();
export const useCurrency = () => useContext(currencyContext);
export default function ProvideCurrency(props) {
  const [currency, setCurrency] = useState(localStorage.getItem('currency') || 'GBP');
  return (
    <currencyContext.Provider value={{ currency, setCurrency }}>
      {props.children}
    </currencyContext.Provider>
  );
}
